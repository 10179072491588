import React from 'react';
import { UnacceptableValueException } from '@hiwaldo/sdk/exceptions';
import {
  FooterCondensedFragment,
  FooterCookiePolicyFragment,
} from '../fragments';
import {
  ButtonInputElement,
  DropdownElement,
  FooterStyles,
  FooterTemplateProps,
  IconElement,
  TextInputElement,
} from '../../..';

export const FooterTemplateDefault = (props: FooterTemplateProps) => {
  if (!props.footerLinks) {
    throw new UnacceptableValueException(
      'Could not load header template: Missing footer links'
    );
  }

  if (!props.regionDropdown) {
    throw new UnacceptableValueException(
      'Could not load header template: Missing region dropdown'
    );
  }

  if (!props.newsletterInputProps) {
    throw new UnacceptableValueException(
      'Could not load header template: Missing newsletter input'
    );
  }

  if (!props.submitInputProps) {
    throw new UnacceptableValueException(
      'Could not load header template: Missing submit input'
    );
  }

  if (!props.cookiePolicyCloseHandler) {
    throw new UnacceptableValueException(
      'Could not load header template: Missing cookie policy close handler'
    );
  }

  return (
    <FooterStyles
      data-cy="footer-partial"
      id={props.id}
      className={props.classes}
    >
      <div className="footer-default-content-container">
        <div className="footer-default-content-titlebar-wrapper">
          <div className="footer-default-content-titlebar">
            <div className="footer-default-content-titlebar-logo">
              <img src="/images/icons/waldo-text.svg" alt="Waldo" />
              <div>Championing your vision</div>
            </div>
            <div className="footer-default-content-titlebar-region">
              <DropdownElement {...props.regionDropdown!} />
            </div>
          </div>
        </div>
        <div className="footer-default-content">
          <div className="footer-default-content-newsletter">
            {!props.newsletterComplete ? (
              <>
                <span>Subscribe to our newsletter</span>
                <div className="footer-default-content-newsletter-input">
                  <TextInputElement {...props.newsletterInputProps!} />
                  <ButtonInputElement {...props.submitInputProps!} />
                </div>
              </>
            ) : (
              <>
                <span>You have signed up!</span>
                <div className="footer-default-content-newsletter-complete">
                  <IconElement
                    template="iconCheckEmpty"
                    styleClasses={['iconBase']}
                  />
                  <div className="footer-default-content-newsletter-thankyou">
                    Thank you for subscribing. Check your email for confirmation
                  </div>
                </div>
              </>
            )}
          </div>
          <div className="footer-default-content-links">
            <div key={0}>
              <span
                className="footer-default-content-link"
                onClick={() => props.footerLinks!.ourStory.onLinkHandler()}
                dangerouslySetInnerHTML={{
                  __html: props.footerLinks!.ourStory.label,
                }}
              ></span>
            </div>
            <div key={1}>
              <span
                className="footer-default-content-link"
                onClick={() => props.footerLinks!.ourBlog.onLinkHandler()}
                dangerouslySetInnerHTML={{
                  __html: props.footerLinks!.ourBlog.label,
                }}
              ></span>
            </div>
            <div key={2}>
              <span
                className="footer-default-content-link"
                onClick={() => props.footerLinks!.reviews.onLinkHandler()}
                dangerouslySetInnerHTML={{
                  __html: props.footerLinks!.reviews.label,
                }}
              ></span>
            </div>
            <div key={3}>
              <span
                className="footer-default-content-link"
                onClick={() => props.footerLinks!.visionaries.onLinkHandler()}
                dangerouslySetInnerHTML={{
                  __html: props.footerLinks!.visionaries.label,
                }}
              ></span>
            </div>
            <div key={4}>
              <span
                className="footer-default-content-link"
                onClick={() => props.footerLinks!.help.onLinkHandler()}
                dangerouslySetInnerHTML={{
                  __html: props.footerLinks!.help.label,
                }}
              ></span>
            </div>
            <div key={5}>
              <span
                className="footer-default-content-link"
                onClick={() => props.footerLinks!.doctors.onLinkHandler()}
                dangerouslySetInnerHTML={{
                  __html: props.footerLinks!.doctors.label,
                }}
              ></span>
            </div>
            <div key={6}>
              <span
                className="footer-default-content-link"
                onClick={() => props.footerLinks!.login.onLinkHandler()}
                dangerouslySetInnerHTML={{
                  __html: props.footerLinks!.login.label,
                }}
              ></span>
            </div>
            <div key={7}>
              <span
                className="footer-default-content-link"
                onClick={() => props.footerLinks!.sightSavers.onLinkHandler()}
                dangerouslySetInnerHTML={{
                  __html: props.footerLinks!.sightSavers.label,
                }}
              ></span>
            </div>
            {props.footerLinks!.yotpoRewards && (
              <div key={8}>
                <span
                  className="footer-default-content-link"
                  onClick={() =>
                    props.footerLinks!.yotpoRewards!.onLinkHandler()
                  }
                  dangerouslySetInnerHTML={{
                    __html: props.footerLinks!.yotpoRewards.label,
                  }}
                ></span>
              </div>
            )}
          </div>
          <div className="footer-default-content-payments">
            <div className="footer-default-content-region">
              <DropdownElement {...props.regionDropdown!} />
            </div>
            <div className="footer-default-content-payment-logos">
              <div className="payment-logo-paypal">
                <img src="/images/payment-cards/paypal.svg" />
              </div>
              <div className="payment-logo-mastercard">
                <img src="/images/payment-cards/mastercard.svg" />
              </div>
              <div className="payment-logo-visa">
                <img src="/images/payment-cards/visa.svg" />
              </div>
              <div className="payment-logo-apple-pay">
                <img src="/images/payment-cards/apple-pay.svg" />
              </div>
              <div className="payment-logo-amex">
                <img src="/images/checkout/payment/checkout-amex.svg" />
              </div>
              <div className="payment-logo-google-pay">
                <img src="/images/payment-cards/google-pay.svg" />
              </div>
            </div>
          </div>
        </div>
      </div>

      <FooterCondensedFragment
        companyLinks={props.companyLinks}
        socialLinks={props.socialLinks}
      />

      <FooterCookiePolicyFragment
        cookiePolicyAccepted={props.cookiePolicyAccepted}
        cookiePolicyCloseHandler={props.cookiePolicyCloseHandler}
        cookiePolicyLinkHandler={props.companyLinks.cookiePolicy.onLinkHandler}
      />
    </FooterStyles>
  );
};
